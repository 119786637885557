window.addEventListener("scroll", function () {
    checkScrollPosition();
}, {passive: true});

function checkScrollPosition(){
    if(window.scrollY > 100){
        document.body.classList.add("scrolled");
    }else if (window.scrollY < 20) {
        document.body.classList.remove("scrolled");
    }
}
checkScrollPosition();