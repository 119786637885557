var Swiper = require('swiper/cjs/components/core/core-class').default;
var Navigation = require('swiper/cjs/components/navigation/navigation').default;
var Pagination = require('swiper/cjs/components/pagination/pagination').default;
var Autoplay = require('swiper/cjs/components/autoplay/autoplay').default;

Swiper.use([Navigation, Autoplay, Pagination]);


document.querySelectorAll(".logo-slider-inner").forEach(slider => {
    if(slider.querySelector(".swiper-slide")){
        const swiper = new Swiper(slider, {
            autoplay: {
                delay: 7500,
            },
            direction: 'horizontal',
            centeredSlides: false,
            loop: true,
            navigation: {
                nextEl: slider.closest(".logo-slider").querySelector('.swiper-button-next'),
                prevEl: slider.closest(".logo-slider").querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: slider.closest(".logo-slider").querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            },
            speed: 400,
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                480: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                1024: {
                    slidesPerView: 7,
                    spaceBetween: 30
                }
            }
        });
    }
});
