var navigation = document.querySelector("header .right .nav");
var hamburger = document.querySelector(".hamburger");
if(hamburger){
    hamburger.addEventListener("click", () => {
        if(navigation.classList.contains("active")){
            navigation.classList.remove("active");
            hamburger.classList.remove("is-active");
            if(window.scrollY > 100){
                document.body.classList.add("scrolled");
            }
        }else{
            navigation.classList.add("active");
            hamburger.classList.add("is-active");
            document.body.classList.remove("scrolled");
        }
    });
}

document.querySelectorAll("header nav a").forEach(link => {
    link.addEventListener("click", () => {
        if(navigation.classList.contains("active")){
            navigation.classList.remove("active");
            hamburger.classList.remove("is-active");
            if(window.scrollY > 100){
                document.body.classList.add("scrolled");
            }
        }
    });
});