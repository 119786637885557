setInterval(() => {
    updateBoxHeight();
}, 100);

window.addEventListener("resize", function(){
    updateBoxHeight();
});

function updateBoxHeight(){
    var containers = document.getElementsByClassName("squared");
    for(var i = 0; i < containers.length; i++){
        var height = 0;
        var items = containers[i].getElementsByClassName("squared-item");
        for(var j = 0; j < items.length; j++){
            items[j].style.height = "auto";
        }
        for(var j = 0; j < items.length; j++){
            var item = items[j];
            if(height < item.clientHeight){
                height = item.clientHeight;
            }
            if(height < item.clientWidth){
                height = item.clientWidth;
            }
        }
        for(var j = 0; j < items.length; j++){
            items[j].style.height = (height + 5) + "px";
        }
    }
}
