var Swiper = require('swiper/cjs/components/core/core-class').default;
var Navigation = require('swiper/cjs/components/navigation/navigation').default;

Swiper.use([Navigation]);


document.querySelectorAll(".testimonials-slider-inner").forEach(slider => {
    if(slider.querySelector(".swiper-slide")){
        const swiper = new Swiper(slider, {
            direction: 'horizontal',
            centeredSlides: true,
            loop: true,
            navigation: {
                nextEl: slider.closest(".testimonials-container").querySelector('.swiper-button-next'),
                prevEl: slider.closest(".testimonials-container").querySelector('.swiper-button-prev'),
            },
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 40
        });
    }
});
