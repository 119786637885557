var Swiper = require('swiper/cjs/components/core/core-class').default;
var Coverflow = require('swiper/cjs/components/effect-coverflow/effect-coverflow').default;
var Navigation = require('swiper/cjs/components/navigation/navigation').default;
var Pagination = require('swiper/cjs/components/pagination/pagination').default;
var Autoplay = require('swiper/cjs/components/autoplay/autoplay').default;

Swiper.use([Coverflow, Navigation, Autoplay, Pagination]);


document.querySelectorAll(".projects-slider-inner").forEach(slider => {
    if(slider.querySelector(".swiper-slide")){
        const swiper = new Swiper(slider, {
            autoplay: {
                delay: 7500,
            },
            centeredSlides: true,
            coverflowEffect: {
                depth: 300,
                rotate: 30,
                slideShadows: false,
            },
            direction: 'horizontal',
            effect: 'coverflow',
            loop: true,
            navigation: {
                nextEl: slider.closest(".projects-slider").querySelector('.swiper-button-next'),
                prevEl: slider.closest(".projects-slider").querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: slider.closest(".projects-slider").querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            },
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 120,
            watchOverflow: true,
            breakpoints: {
                640: {
                    slidesPerView: 2
                }
            }
        });
    }
});
